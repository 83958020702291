<template>
  <div class="main-panel">
    <div class="title">
      <span>被拒绝泛团</span>
      <div class="d-inline-block ml-5 search">
        <el-input type="text" clearable v-model="search" placeholder="请输入ID或泛团名" style="width: 350px;"></el-input>
        <el-button type="primary" @click="searchTeam" style="margin-left: -3px;">搜索</el-button>
      </div>
    </div>
    <myTable :list="list" :name='name'>
      <template #before='scoped'>
        <div class="expand-main">
          <div class="row">
            <span>联系电话：{{scoped.row.phone}}</span>
            <span>联系邮箱：{{scoped.row.email}}</span>
          </div>
          <div class="row warp">
            <span>泛团简介：</span>
            <span>{{scoped.row.level}}</span>
          </div>
          <div class="row last-row">
            泛团备注：<el-input disabled="true" v-model="scoped.row.comment" size="mini" ></el-input>
          </div>
        </div>
			</template>
    </myTable>
    <el-pagination v-if="list.length > 0" layout="prev, pager, next" :page-size="10"  :total="totalRow" @current-change="changePage">
  </el-pagination>
  </div>
</template>

<script>
import myTable from './my-table.vue'
import { getTeamsInfo } from '@/api/team-info'
export default {
  name: 'refused',
  components: {
    myTable
  },
  data () {
    return {
      search: '',
      name: 'refused',
      page: 1,
      totalRow: 0,
      list: []
    }
  },
  mounted () {
    this.getList()
    // 0
  },
  methods: {
    async getList () {
      const params = {
        search: this.search,
        page: this.page,
        pageSize: 10,
        status: 2
      }
      const res = await getTeamsInfo(params)
      if (res.code === 0) {
        this.totalRow = res.data.pager.totalRow
        this.list = res.data.teams || []
      } else {
        this.$notify({
          title: '失败',
          type: 'error',
          message: res.message,
          duration: 2000
        })
      }
    },
    searchTeam () {
      this.page = 1
      this.getList()
    },
    changePage (p) {
      this.page = p
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.main-panel{
  .title{
    font-size: 20px;
    line-height: 20px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 36px;
  }
  .el-pagination{
    margin-top: 190px;
  }
  .expand-main{
    overflow: hidden;
    padding: 0 1px;
    .last-row{
      padding-right: 50px;
      /deep/.el-input__inner{
        border-radius: 6px !important;
        border: 1px solid #BBBBBB;
      }
    }
    .row{
      display: flex;
      margin: 0;
      margin-bottom: 10px;
      /deep/.el-input{
        flex: 1;
        .el-input__inner{
          border-radius: 0px
        }
        .el-input__inner:focus{
          border: 1px solid #707070;
        }
      }
      span{
        flex: 1;
      }
    }
    .warp{
      width: 100%;
      display: flex;
      span:first-child{
        flex: 1;
        max-width: 70px;
        min-width: 70px;
      }
      span:st-child{
        flex: 9;
        width: 100%;
      }
    }
  }
}
</style>
